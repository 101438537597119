import React from 'react';
import { navigate } from 'gatsby-link';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const BottomMenuBox = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  padding: '1.5rem 0',
});

const BottomMenuWrapper = css({
  maxWidth: '1200px',
  margin: '0 auto',
});

const subListItem = css({
  paddingLeft: '1rem',
});

const listStyle = css({
  width: '90%',
  margin: '0 auto',
});

const BottomMenu: React.VFC = () => {
  return (
    <section>
      <Box css={BottomMenuBox}>
        <Grid container css={BottomMenuWrapper}>
          <Grid item xs={12} md={4}>
            <List css={listStyle}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/')}>
                  <ListItemText
                    primary="TOP"
                    secondary="トップページ"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/works')}>
                  <ListItemText
                    primary="WORKS"
                    secondary="施工実績"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding css={subListItem}>
                <ListItemButton onClick={() => navigate('/works#architecture')}>
                  <ListItemText
                    primary="建築施工例"
                    primaryTypographyProps={{fontSize: '.8rem'}}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding css={subListItem}>
                <ListItemButton onClick={() => navigate('/works#renovation')}>
                  <ListItemText
                    primary="リフォーム施工例"
                    primaryTypographyProps={{fontSize: '.8rem'}}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding css={subListItem}>
                <ListItemButton onClick={() => navigate('/works#civilEngineering')}>
                  <ListItemText
                    primary="土木施工例"
                    primaryTypographyProps={{fontSize: '.8rem'}}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List css={listStyle}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/second-opinion')}>
                  <ListItemText
                    primary="SECOND OPINION"
                    secondary="建築セカンドオピニオン"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/history')}>
                  <ListItemText
                    primary="HISTORY"
                    secondary="社史"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/philosophy')}>
                  <ListItemText
                    primary="PHILOSOPHY"
                    secondary="私たちの哲学"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/company')}>
                  <ListItemText
                    primary="COMPANY"
                    secondary="会社情報"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List css={listStyle}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/quality')}>
                  <ListItemText
                    primary="QUALITY"
                    secondary="品質管理"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/csr')}>
                  <ListItemText
                    primary="CSR"
                    secondary="社会貢献活動"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/contact')}>
                  <ListItemText
                    primary="CONTACT"
                    secondary="お問い合わせ"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default BottomMenu;
