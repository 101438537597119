import React from 'react';
import { navigate } from 'gatsby-link';
import { useAtom } from 'jotai';

import { css } from '@emotion/react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import toggleDrawer from '@/others/toggleDrawer';
import { menuOpenStatus } from '@/store/store';

const menuListStyles = css({
  width: '270px',
});

const subMenuItem = css({
  paddingLeft: '1rem',
});

const MenuDrawer: React.VFC = () => {
  const [isOpen, setIsOpen] = useAtom(menuOpenStatus);
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const handleClick = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  return(
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={toggleDrawer(true, setIsOpen)}
      onClose={toggleDrawer(false, setIsOpen)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <List css={menuListStyles}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/')}>
            <ListItemText
              primary="TOP"
              secondary="トップページ"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/works')}>
            <ListItemText
              primary="WORKS"
              secondary="施工実績"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding css={subMenuItem}>
          <ListItemButton onClick={() => handleClick('/works#architecture')}>
            <ListItemText
              primary="建築施工例"
              primaryTypographyProps={{fontSize: '.8rem'}}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding css={subMenuItem}>
          <ListItemButton onClick={() => handleClick('/works#renovation')}>
            <ListItemText
              primary="リフォーム施工例"
              primaryTypographyProps={{fontSize: '.8rem'}}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding css={subMenuItem}>
          <ListItemButton onClick={() => handleClick('/works#civilEngineering')}>
            <ListItemText
              primary="土木施工例"
              primaryTypographyProps={{fontSize: '.8rem'}}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/second-opinion')}>
            <ListItemText
              primary="SECOND OPINION"
              secondary="建築セカンドオピニオン"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/history')}>
            <ListItemText
              primary="HISTORY"
              secondary="社史"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton  onClick={() => handleClick('/philosophy')}>
            <ListItemText
              primary="PHILOSOPHY"
              secondary="私たちの哲学"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/company')}>
            <ListItemText
              primary="COMPANY"
              secondary="会社情報"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/quality')}>
            <ListItemText
              primary="QUALITY"
              secondary="品質管理"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/csr')}>
            <ListItemText
              primary="CSR"
              secondary="社会貢献活動"
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleClick('/contact')}>
            <ListItemText
              primary="CONTACT"
              secondary="お問い合わせ"
            />
          </ListItemButton>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
